
/* eslint max-lines: off */
import {defineComponent, onMounted, reactive, ref} from 'vue';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import BlockUI from 'primevue/blockui';
import InlineMessage from 'primevue/inlinemessage';
import FileUpload from "@/components/DexFileUpload.vue";
import EntryDialog from "@/components/EntryDialog.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import {ClientManager} from "@/singletons/ClientManager";
import {ToastManager} from "@/util/ToastManager";
import { useI18n } from "vue-i18n";
import { useToast } from "primevue/usetoast";
import {
	DocumentField,
	DocumentTable,
	XMLNameSpace,
	XPathDocumentMapping,
	XPathFieldMapping,
	XPathTableMapping
} from "@dex/squeeze-client-ts";
import { UploadFile } from "@/shims-prime-vue";
import {VueFlow, useVueFlow, Edge} from "@vue-flow/core";
import { Controls } from '@vue-flow/controls';
import CustomNode from "@/apps/administration/components/documentclasses/CustomNode.vue";
import {Background} from "@vue-flow/background";
import LabelNode from "@/apps/administration/components/documentclasses/LabelNode.vue";
import DataNode from "@/apps/administration/components/documentclasses/DataNode.vue";
import MiniMap from "@/apps/administration/components/documentclasses/minimap/MiniMap.vue";

export default defineComponent({
	name: "MapperView",
	components: {
		DataNode,
		LabelNode,
		Background,
		CustomNode,
		InputText,
		Dialog,
		BlockUI,
		InlineMessage,
		FileUpload,
		VueFlow,
		Controls,
		MiniMap,
		EntryDialog,
		DialogDelete,
	},
	props: {
		documentClassId: {
			type: Number,
			default: 0,
		},
	},
	setup(props) {
		const {t} = useI18n();
		const toast = useToast();

		/** Show loading? */
		const loading = ref<boolean>(false);

		/** Should be the mapper blocked? */
		const blockMapper = ref<boolean>(false);

		/** Should be the mapper data be expanded? */
		const expandAll = ref<boolean>(false);

		/** Has node template changed */
		const hasNodeTemplateChanged = ref<boolean>(false);

		/** All Nodes */
		const nodes = ref<any[]>([]);

		/** All Edges */
		const edges = ref<Edge[]>([]);

		const { onConnect, onInit, addEdges, applyEdgeChanges, zoomIn, zoomOut, setViewport } = useVueFlow();

		onInit((vueFlowInstance) => {
			// instance is the same as the return of `useVueFlow`
			vueFlowInstance.fitView();
		});

		onConnect((params) => addEdges([params]));

		/** Document-Class-Api */
		const documentClassApi = ClientManager.getInstance().squeeze.documentClass;

		/** XML Api */
		const XMLApi = ClientManager.getInstance().squeeze.xml;

		/** Show Upload-Dialog? */
		const showUploadDialog = ref<boolean>(false);

		/** Label of the Upload */
		const uploadLabel = ref(t("Squeeze.General.Upload"));

		/** List of all files to be uploaded */
		const files = ref<any[]>([{
			uploadFinished: false,
			loading: false,
			errorText: '',
			error: false,
		}]);

		/** Current Progress of upload */
		const progress = ref<number>(0);

		/** Current Mapping */
		const mapping = reactive<XPathDocumentMapping>({
			id: undefined,
			name: '',
			documentClassId: props.documentClassId,
			classificationFields: [],
			namespaces: [],
			fields: [],
			tables: [],
			systemMapping: false,
		});

		/** Show Save-Dialog? */
		const showSaveDialog = ref<boolean>(false);

		/** Show Delete-Dialog? */
		const deleteDialog = ref<boolean>(false);

		/** All Mappings of document (class) */
		const allMappings = ref<XPathDocumentMapping[]>([]);

		/** Name spaces of xml/pdf */
		const namespaces = ref<any>([]);

		/** All fields */
		const allFields = ref<DocumentField[]>([]);

		/** All tables */
		const allTables = ref<DocumentTable[]>([]);

		/** Triggered when the zoom in button is clicked */
		const zoomInButton = () => {
			zoomIn();
		}

		/** Triggered when the zoom out button is clicked */
		const zoomOutButton = () => {
			zoomOut();
		}

		/** Triggered when the fit view button is clicked */
		const fitViewBtn = () => {
			setViewport({ x: 0, y: 0, zoom: 1 })
		}

		/** Reset Mapping */
		const resetMapping = () => {
			Object.assign(mapping, {
				id: undefined,
				name: '',
				documentClassId: props.documentClassId,
				classificationFields: [],
				namespaces: [],
				fields: [],
				tables: [],
			});

			blockMapper.value = false;
		}

		/** Reset data of mapper */
		const resetData = () => {
			// remove mapping edges
			edges.value = edges.value.filter(edge => edge.data.type !== 'Mapping');
		}

		/** Get name spaces */
		const getNameSpaces = () => {
			const nameSpaces: XMLNameSpace[] = [];

			Object.keys(namespaces.value[0]).forEach(key => {
				nameSpaces.push({
					id: undefined,
					prefix: key,
					uri: namespaces.value[0][key],
				});
			});

			return nameSpaces;
		}

		/**
		 * Get field mappings
		 * @param allLinkedData
		 */
		const getFieldMappings = (allLinkedData: any) => {
			const fieldMappings: XPathFieldMapping[] = [];

			allLinkedData.forEach((linkedData: any) => {
				const field = allFields.value.find(field => field.name === linkedData.data.targetNode.data.name);
				if (field && field.id) {
					const node = nodes.value.find((node: any) => node.id === linkedData.source);
					fieldMappings.push({
						id: undefined,
						fieldId: field.id,
						xpath: node.data.xpath,
						alternative: node.data.alternative,
					});
				}
			});

			return fieldMappings;
		}

		/**
		 * Get table mappings
		 * @param allLinkedData
		 */
		const getTableMappings = (allLinkedData: any) => {
			const tableMappings: XPathTableMapping[] = [];

			allLinkedData.forEach((linkedData: any) => {
				const columnMappings: XPathFieldMapping[] = [];
				const table = allTables.value.find(table => table.name === linkedData.data.targetNode.data.name);
				if (table && table.id) {
					const tableNode = nodes.value.find((node: any) => node.id === linkedData.source);
					if (tableMappings.length > 0) {
						tableMappings.forEach(tableMapping => {
							if (tableMapping.tableId === table.id) {
								// add table xpath when table exists
								tableMapping.tableRootXPath = tableNode.data.xpath;
								// check if columns not exists
								if (!tableMapping.columns) {
									tableMapping.columns = [];
								}
								return;
							}
						})
					} else {
						tableMappings.push({
							id: undefined,
							tableId: table.id,
							tableRootXPath: tableNode.data.xpath,
							columns: columnMappings,
						});
					}
				} else {
					allTables.value.forEach(table => {
						if (table.columns) {
							const column = table.columns.find(column => column.name === linkedData.data.targetNode.data.name);
							if (column && column.id) {
								const columnNode = nodes.value.find((node: any) => node.id === linkedData.source);
								columnMappings.push({
									id: undefined,
									fieldId: column.id,
									xpath: columnNode.data.xpath,
									alternative: columnNode.data.alternative,
								});

								if (tableMappings.length > 0) {
									tableMappings.forEach(tableMapping => {
										if (tableMapping.tableId === column.tableId) {
											// add new column mapping to table columns
											tableMapping.columns!.push(columnMappings[0]);
										}
									})
								} else {
									const tableNode = {id: undefined, xpath: ''};

									// get parent (table) key of linked data
									const tableNodeKey = linkedData.source.substr(0, linkedData.source.lastIndexOf("."));
									if (tableNodeKey) {
										const node = nodes.value.find((n: any) => n.id === tableNodeKey);
										if (node) {
											Object.assign(tableNode, node);
										}
									}

									tableMappings.push({
										id: undefined,
										tableId: table.id,
										tableRootXPath: tableNode.xpath,
										columns: columnMappings,
									});
								}
							}
						}
					})
				}
			});

			return tableMappings;
		}

		/**
		 * Get field name by ID
		 * @param fieldId
		 */
		const getFieldNameById = (fieldId: number) => {
			// get field name
			const field = allFields.value.find(field => field.id === fieldId);
			if (field) {
				// get node element of the field by name
				const nodeOfField = nodes.value.find((node: any) => node.data.name === field.name);
				return nodeOfField ? nodeOfField.id : fieldId;
			}
			return fieldId;
		}

		/**
		 * Get column name by ID
		 * @param columnId
		 */
		const getColumnNameById = (columnId: number) => {
			let columnName = '';
			allTables.value.find(table => {
				if (table && table.columns && table.columns.length) {
					const column = table.columns.find(column => column.id === columnId);
					if (column && column.name) {
						// get node element of the column by name
						const nodeOfColumn = nodes.value.find((node: any) => node.data.name === column.name);
						columnName = nodeOfColumn.id;
						return;
					}
				}
			});
			return columnName;
		}

		/** Get document class data */
		const getDocumentClassData = async () => {
			const getDocumentClass = documentClassApi.getDocumentClassById(props.documentClassId);
			const getAllFieldGroups = documentClassApi.getAllFieldGroups(props.documentClassId);
			const getAllFields = documentClassApi.getAllDocumentClassFields(props.documentClassId);
			const getAllTables = documentClassApi.getAllDocumentClassTables(props.documentClassId);

			await Promise.all([getDocumentClass, getAllFieldGroups, getAllFields, getAllTables])
				.then(promises => {
					let yPosition: number = 0;
					const documentClassName = promises[0].name;
					nodes.value.push({id: '2', type: 'label', data: { label: documentClassName }, position: { x: 600, y: yPosition }, class: 'light'});

					if (promises[1].length) {
						allFields.value = promises[2];
						let fgIndex: number = 0;
						promises[1].forEach((fieldGroup, index) => {
							if (index > fgIndex || index === 0) {
								fgIndex = index;
								yPosition = yPosition + 30;
							}
							// get all fields of a field group
							const allFieldsOfFieldGroup: DocumentField[] = allFields.value.filter(field => field.fieldGroupId === fieldGroup.id);
							if (allFieldsOfFieldGroup.length) {
								// add node element of field group when fields exists
								nodes.value.push({id: '2-' + fieldGroup.name, type: 'custom', data: { text: fieldGroup.description }, position: { x: 625, y: yPosition }, class: 'light'});
								edges.value.push({id: 'e2-2-' + fieldGroup.name, type: 'smoothstep', source: '2', target: '2-' + fieldGroup.name, data: { type: 'treeConnection' }});

								let fieldIndex: number = 0;
								allFieldsOfFieldGroup.forEach((field, fIndex) => {
									if (fIndex > fieldIndex || fIndex === 0) {
										fieldIndex = fIndex;
										yPosition = yPosition + 30;
									}
									nodes.value.push({id: '2-' + fieldGroup.name + '-' + field.name, type: 'custom', data: { name: field.name, text: field.description }, position: { x: 650, y: yPosition }, class: 'light'});
									edges.value.push({id: 'e2-2-' + fieldGroup.name + '-' + field.name, type: 'smoothstep', source: '2-' + fieldGroup.name, target: '2-' + fieldGroup.name + '-' + field.name, data: { type: 'treeConnection' }});
								});
							}
						})
					}

					if (promises[3].length) {
						Promise.all(promises[3].map(table => {
							if (table.id) {
								return documentClassApi.getAllDocumentClassTableColumns(props.documentClassId, table.id)
									.then(cols => {
										table.columns = cols;
									})
							}
						}));

						allTables.value = promises[3];

						let tableIndex = 0;
						promises[3].forEach((table, tIndex) => {
							if (tIndex > tableIndex) {
								tableIndex = tIndex;
								yPosition = yPosition + 30;
							}

							// add node elements of columns
							if (table.columns && table.columns.length) {
								// add node element of table when columns exists
								nodes.value.push({id: '2-' + table.name, type: 'custom', data: { name: table.name, text: table.description }, position: { x: 625, y: yPosition }, class: 'light'});
								edges.value.push({id: 'e2-2-' + table.name, type: 'smoothstep', source: '2', target: '2-' + table.name, data: { type: 'treeConnection' }});

								let columnIndex = 0;
								table.columns.forEach((column, cIndex) => {
									if (cIndex > columnIndex || cIndex === 0) {
										columnIndex = cIndex;
										yPosition = yPosition + 30;
									}
									nodes.value.push({id: '2-' + table.name + '-' + column.name, type: 'custom', data: { name: column.name, text: column.description }, position: { x: 650, y: yPosition }, class: 'light'});
									edges.value.push({id: 'e2-2-' + table.name + '-' + column.name, type: 'smoothstep', source: '2-' + table.name, target: '2-' + table.name + '-' + column.name, data: { type: 'treeConnection' }});
								});
							}
						})
					}
				}).catch(error => {
					ToastManager.showError(toast, t('Squeeze.General.Error'), error);
				})
		}

		function getChildNodes(objectKey: any, oldIndex: any) {
			Object.keys(objectKey).forEach((key, index) => {
				const arrayHasAlternativeItems = objectKey[key].attributes && objectKey[key].attributes.length > 0;
				const fullIndex = oldIndex + '.' + index;
				if (index > 2) {
					nodes.value.push({id: fullIndex, type: 'data', data: { label: key, xpath: objectKey[key].xpath, value: objectKey[key].value, alternative: arrayHasAlternativeItems }, position: { x: 0, y: 0 }, class: 'light'});
					edges.value.push({id: 'e1-' + fullIndex, type: 'smoothstep', source: oldIndex ? oldIndex : '1', target: fullIndex, data: { type: 'treeConnection' }});
					if (arrayHasAlternativeItems) {
						objectKey[key].attributes.forEach((attribute: any, attributeIndex: number) => {
							const attributeFullIndex = fullIndex + '.' + attributeIndex;
							const attributeHasAlternativeItems = attribute.alternative && attribute.alternative.length > 0;
							nodes.value.push({id: attributeFullIndex, type: 'data', data: { label: '@' + attribute.name, xpath: attribute.xpath, value: attribute.value, alternative: attributeHasAlternativeItems }, position: { x: 0, y: 0 }, class: 'light'});
							edges.value.push({id: 'e1-' + attributeFullIndex, type: 'smoothstep', source: fullIndex, target: attributeFullIndex, data: { type: 'treeConnection' }});
						});
					}

					getChildNodes(objectKey[key], fullIndex);
				} else if (arrayHasAlternativeItems) {
					objectKey[key].attributes.forEach((attribute: any, attributeIndex: number) => {
						const attributeFullIndex = fullIndex + '.' + attributeIndex;
						const attributeHasAlternativeItems = attribute.alternative && attribute.alternative.length > 0;
						nodes.value.push({id: attributeFullIndex, type: 'data', data: { label: '@' + attribute.name, xpath: attribute.xpath, value: attribute.value, alternative: attributeHasAlternativeItems }, position: { x: 0, y: 0 }, class: 'light'});
						edges.value.push({id: 'e1-' + attributeFullIndex, type: 'smoothstep', source: fullIndex, target: attributeFullIndex, data: { type: 'treeConnection' }});
					});
				}
			});
		}

		/** Set position of nodes (X and Y) */
		const setPosition = () => {
			let yPosition: number = 30;
			nodes.value.forEach(node => {
				if (node.type === 'data') {
					node.position.y = yPosition;
					yPosition = yPosition + 30;

					// check how many points are present and multiply with 25 (Indention)
					const countOfDots = node.id.match(/\./g).length;
					node.position.x = 25 * countOfDots;
				}
			})
		}

		/** Process data */
		const processData = async (response: any) => {
			// reset data
			nodes.value = [];
			edges.value = [];

			Object.keys(response).forEach((key, index) => {
				if (index === 0) {
					nodes.value.push({id: '1', type: 'label', data: { label: key }, position: { x: 0, y: 0 }, class: 'light'});
					getChildNodes(response[key], index);
					setPosition();
				} else {
					namespaces.value.push(response[key]);
				}
			});

			await getDocumentClassData();
		}

		/** Get Mapping */
		const getMapping = async () => {
			// links to fields
			if (mapping.fields && mapping.fields.length) {
				mapping.fields.forEach(field => {
					const currentNode = nodes.value.find((node: any) => node.data.xpath === field.xpath);
					if (currentNode) {
						edges.value.push({
							id: 'e1-' + currentNode.id,
							type: 'smoothstep',
							sourceHandle: "sourceDataNode",
							source: currentNode.id,
							targetHandle: "targetCustomNode",
							target: getFieldNameById(field.fieldId!),
							data: { type: 'Mapping' },
						});
					}
				});
			}

			// links to tables and columns
			if (mapping.tables && mapping.tables.length) {
				mapping.tables.forEach(table => {
					const currentTableNode = nodes.value.find((node: any) => node.data.xpath === table.tableRootXPath);
					if (currentTableNode) {
						const currentTable = allTables.value.find(tab => tab.id === table.tableId);
						edges.value.push({
							id: 'e1-' + currentTableNode.id,
							type: 'smoothstep',
							sourceHandle: "sourceDataNode",
							source: currentTableNode.id,
							targetHandle: "targetCustomNode",
							target: currentTable!.name!,
							data: { type: 'Mapping' },
						});
					}

					if (table!.columns && table!.columns.length) {
						table.columns!.forEach(column => {
							const currentNode = nodes.value.find((node: any) => node.data.xpath === column.xpath);
							if (currentNode) {
								edges.value.push({
									id: 'e1-' + currentNode.id,
									type: 'smoothstep',
									sourceHandle: "sourceDataNode",
									source: currentNode.id,
									targetHandle: "targetCustomNode",
									target: getColumnNameById(column.fieldId!),
									data: { type: 'Mapping' },
								});
							}
						})
					}
				});
			}
		}

		/** Reload data */
		const reloadData = async () => {
			resetMapping();
			resetData();
			await getDocumentClassData();
		}

		/** Triggered when edges change
		 * an edge can be added or removed
		 */
		const onEdgesChange = async (changes: any) => {
			applyEdgeChanges(changes);
			changes.forEach((change: any) => {
				if (change.type === 'add') {
					hasNodeTemplateChanged.value = true;
					edges.value.push({
						id: change.item.id,
						type: 'smoothstep',
						source: change.item.source,
						target: change.item.target,
						data: {
							type: 'Mapping',
							sourceNode: change.item.sourceNode,
							targetNode: change.item.targetNode,
						},
					});
				} else if (change.type === 'remove') {
					edges.value.forEach((edge, index) => {
						if (change.id === edge.id) {
							edges.value.splice(index, 1);
						}
					})
					hasNodeTemplateChanged.value = edges.value.filter((linkData: any) => linkData.data.type === 'Mapping').length > 0;
				}
			})
		}

		/** Save the current Mapping */
		const saveMapping = () => {
			loading.value = true;
			const allLinkedData = edges.value.filter((linkData: any) => linkData.data.type === 'Mapping');

			mapping.fields = getFieldMappings(allLinkedData);
			mapping.tables = getTableMappings(allLinkedData);

			let promise;

			if (mapping && mapping.id) {
				// Update mapping
				promise = XMLApi.updateDocumentMapping(props.documentClassId, mapping.id, mapping);
			} else {
				// get name spaces when mapping not exists
				mapping.namespaces = getNameSpaces();

				// Create new mapping
				promise = XMLApi.postDocumentMapping(props.documentClassId, mapping);
			}

			promise.then((data: any) => {
				mapping.id = data.id;
				hasNodeTemplateChanged.value = false;
				ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.DocumentClasses.MapperSuccuessSave'));
			}).catch(response => response.json().then ((err: { message: string }) => {
				ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
			})).finally(() => {
				showSaveDialog.value = false;
				loading.value = false;
				blockMapper.value = true;
			})
		}

		/** Delete a Mapping */
		const deleteMapping = () => {
			loading.value = true;
			XMLApi.deleteDocumentMapping(mapping.id!)
				.then(async () => {
					await reloadData();
					ToastManager.showSuccess(toast, t('Squeeze.General.Success'), t('Squeeze.DocumentClasses.MapperSuccuessDelete'));
				}).catch(response => response.json().then ((err: { message: string }) => {
					ToastManager.showError(toast, t('Squeeze.General.ErrorDelete'), t('Squeeze.General.DeleteError') + ": " + err.message);
				})).finally(() => {
					loading.value = false;
				})
		}

		onMounted(async () => {
			try {
				loading.value = true;
				await reloadData();
			} catch (error) {
				ToastManager.showError(toast, t('Squeeze.General.Error'), error);
			} finally {
				loading.value = false;
			}
		})

		// region file upload

		/**
		 * Is triggered when files are selected from the Component
		 * @param event File-Select event
		 */
		const onSelectFiles = (event: any) => {
			// Reset array and only take last entry of the selected files-array which is too big if selected multiple times
			files.value = [];
			files.value[0] = event.files[event.files.length - 1];
			uploadLabel.value = t("Squeeze.General.Upload") + " (0/1)";
		}

		/**
		 * Is triggered when the "clear" button is pressed in the Upload-Component
		 */
		const clearFiles = () => {
			uploadLabel.value = t("Squeeze.General.Upload");
		}

		/**
		 * Is triggered when a single file is removed from upload
		 * @param event
		 */
		const removeFile = (event: any) => {
			files.value = event.files;
			uploadLabel.value = t("Squeeze.General.Upload") + " (" + files.value.filter(file => file.uploadFinished).length + "/" + files.value.length + ")";
		}

		/**
		 * Manual file upload to the Squeeze API. This has been programmed because the generated API client does not
		 * support multipart/form-data requests: https://github.com/swagger-api/swagger-codegen/issues/3921
		 * @param file
		 * @returns Object with the id of the created document
		 */
		const manualFileUpload = async (file: UploadFile) => {
			const body = new FormData();
			body.set("document", file);

			const response = await ClientManager.getInstance().customFetch(ClientManager.getInstance().getSqueezeBasePath() + "/xml/xpath", {
				method: "POST",
				body: body,
			});

			if (response.status !== 200) {
				const responseJSON = await response.json();
				throw new Error(responseJSON.message);
			}

			return await response.json();
		}

		/**
		 * Manual file upload to the Squeeze API, so that identify mapping of a document.
		 * @param file
		 * @returns Object of identify mappings for document
		 */
		const identifyMapping = async (file: UploadFile) => {
			const body = new FormData();
			body.set("document", file);

			const response = await ClientManager.getInstance().customFetch(ClientManager.getInstance().getSqueezeBasePath() + "/xml/xpath/mappings/identify", {
				method: "POST",
				body: body,
			});

			if (response.status !== 200) {
				throw response;
			}

			return await response.json();
		}

		/**
		 * Uploads the files from the file-uploader
		 * @param event
		 */
		const fileUploader = (event: any) => {
			files.value = event.files;

			progress.value = 0;
			// Calculate progress
			uploadLabel.value = t("Squeeze.General.Upload") + " (" + files.value.filter(file => file.uploadFinished).length + "/" + files.value.length + ")";

			event.files
				.forEach((file: any, index: number) => {
					if (!file.uploadFinished) { // Files that are already finished shouldn't be uploaded again
						const idx = index;
						files.value[idx].error = false;
						files.value[idx].errorText = "";
						files.value[idx].loading = true;
						files.value = [...files.value];

						manualFileUpload(file)
							.then(async (response) => {
								showUploadDialog.value = false;
								files.value[idx].uploadFinished = true;

								try {
									loading.value = true;
									blockMapper.value = false;
									await processData(response);

									await identifyMapping(file)
										.then(async (response) => {
											blockMapper.value = true;
											Object.assign(mapping, response.mapping);
											namespaces.value.push(response.mapping.namespaces);
											await getMapping();
										})
										.catch(response => response.json().then ((err: { message: string; statusCode: number }) => {
											if (err.statusCode === 404) {
												resetMapping();
												return;
											}
											ToastManager.showError(toast, t('Squeeze.General.Error'), t('Squeeze.General.Error') + ": " + err.message);
										}))
								} catch (error) {
									ToastManager.showError(toast, t('Squeeze.General.Error'), error);
								} finally {
									loading.value = false;
								}
							})
							.catch(err => {
								files.value[idx].error = true;
								files.value[idx].errorText = err.message;
							})
							.finally(() => {
								files.value[idx].loading = false;
								files.value = [...files.value];

								// Calculate progress
								const finished = files.value.filter(file => file.uploadFinished);
								progress.value = Math.round((finished.length * 100) / files.value.length);
								uploadLabel.value = t("Squeeze.General.Upload") + " (" + finished.length + "/" + files.value.length + ")";
							});
					}
				})
		}

		// end region

		return {
			toast,
			t,
			loading,
			blockMapper,
			expandAll,
			hasNodeTemplateChanged,
			nodes,
			edges,
			showUploadDialog,
			uploadLabel,
			files,
			progress,
			mapping,
			showSaveDialog,
			deleteDialog,
			allMappings,
			zoomInButton,
			zoomOutButton,
			fitViewBtn,
			processData,
			getMapping,
			reloadData,
			onEdgesChange,
			saveMapping,
			deleteMapping,
			onSelectFiles,
			clearFiles,
			removeFile,
			fileUploader,
		};
	},
});

